import React, {useEffect, useState} from 'react';
import {Box, Typography, Grid, Button, Container, Avatar, CircularProgress} from '@mui/material';
import {useNavigate} from "react-router-dom";
import axios from "axios";

import {searchFighterId} from "../API";

import UnknownFighterHeadshot from "../Resources/Unknown_Fighter.png";

const CACHE_KEY = 'ufcFighterCache';
const CACHE_EXPIRY = 24 * 60 * 60 * 1000; // 24 hours

const LandingPage = ({events}) => {

    const navigate = useNavigate();

    const [fighters, setFighters] = useState({
        one: null,
        two: null
    });

    const onFighterClick = (fighterName) => {
        const encodedSearchTerm = encodeURIComponent(fighterName);
        navigate(`/fighter/${encodedSearchTerm}`);
    };


    const formatDate = (date) => {
        const dateString = new Date(date);

        const formattedDate = dateString.toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZoneName: 'short',
        });

        return formattedDate;
    };

    const calculateStatsFromRsponse = (APIResponse) =>{
        const { categories } = APIResponse.data.fighter_stats;
        const averages = new Map();

        categories.forEach((category) => {
            const { labels, statistics } = category;

            labels.forEach((label, index) => {
                let sum = 0;
                let count = 0;

                statistics.forEach((stat) => {
                    const value = stat.stats[index];

                    if (value.includes('/')) {
                        // For values like "14/23", calculate the percentage
                        const [landed, attempted] = value.split('/').map(Number);
                        if (attempted > 0) {
                            sum += (landed / attempted) * 100;
                            count++;
                        }
                    } else if (value.includes('%')) {
                        // For percentage values like "65.38%", just convert them to numbers
                        const percentageValue = parseFloat(value.replace('%', ''));
                        sum += percentageValue;
                        count++;
                    } else {
                        // For plain numeric values
                        const numericValue = parseFloat(value);
                        if (!isNaN(numericValue)) {
                            sum += numericValue;
                            count++;
                        }
                    }
                });

                const average = count > 0 ? sum / count : 0;
                averages.set(label, average.toFixed(2)); // Storing as a string with 2 decimal places
            });
        });

        return averages;
    }

    useEffect(() => {

        const fetchFighters = async () => {
            const cachedData = localStorage.getItem(CACHE_KEY);
            const now = new Date();

            // Check for caches fighers, if there are load them and continue.
            if (cachedData) {
                const {timestamp, data} = JSON.parse(cachedData);
                if (now - new Date(timestamp) < CACHE_EXPIRY) {
                    setFighters(data);
                    console.log('Using cached fighter data');
                    return;
                }
            }
            try {

                console.log("Comp: ", events.currentEvent.competitions.length);

                const comp = events.currentEvent.competitions.length - 1;

                const fighterOneId = await searchFighterId(events.currentEvent.competitions[comp].competitors[0].athlete.fullName)
                const fighterTwoId = await searchFighterId(events.currentEvent.competitions[comp].competitors[1].athlete.fullName)

                const getFighterOptions = (fighterId) => ({
                    method: 'GET',
                    url: 'https://mma-api1.p.rapidapi.com/player-details',
                    params: {
                        fighterId: fighterId
                    },
                    headers: {
                        'x-rapidapi-key': 'c81b39aea2msh876f66e2467cbe9p14b42cjsne818161970f0',
                        'x-rapidapi-host': 'mma-api1.p.rapidapi.com'
                    }
                });

                const response_One = await axios.request(getFighterOptions(fighterOneId));
                const response_Two = await axios.request(getFighterOptions(fighterTwoId));

                const fighterData = {
                    one: response_One.data.fighter_full_detail.athlete,
                    two: response_Two.data.fighter_full_detail.athlete
                }

                setFighters(fighterData);

                localStorage.setItem(CACHE_KEY, JSON.stringify({ timestamp: now, data: fighterData }));
                console.log('Event Data cached');

                console.log('Response One: ', response_One);
                console.log('Response Two: ', response_Two);

            } catch (error) {
                console.log(error);
            }

        };

        if(events.currentEvent != null){
            fetchFighters();
        }

    }, [events]);

    useEffect(() => {

        console.log('Fighter Stats One: ', fighters.one);
        console.log('Fighter Stats Two: ', fighters.two);
    }, []);

    return (
        <Box sx={{bgcolor: 'background.default', minHeight: '100vh'}}>
            <HeroSection/>
            <Container maxWidth="lg">
                {events.currentEvent && fighters.one && fighters.two? (
                    <Box sx={{ paddingTop: 5}}>
                        <Typography variant="h4" gutterBottom align="center"
                                    color="text.primary">
                            Next Up!
                        </Typography>
                        <Typography variant="h5" align="center"
                                    color="textSecondary">
                            {events.currentEvent.name}
                        </Typography>

                        <Typography variant="h6" align="center"
                                    color="textSecondary">
                            {formatDate(events.currentEvent.date)}
                        </Typography>

                        <Box display="flex" justifyContent="center" sx={{ mt: 2, position: 'relative' }}>
                            <MainFighterProfile
                                fighter={fighters.one}
                                onClick={onFighterClick}
                            />

                            <Typography
                                variant="h5"
                                component="div"
                                color='secondary.light'
                                sx={{
                                    position: 'absolute',
                                    left: '50%',
                                    transform: 'translate(-50%, 200px)',
                                }}
                            >
                                VS
                            </Typography>
                            <MainFighterProfile
                                fighter={fighters.two}
                                onClick={onFighterClick}
                            />

                        </Box>

                    </Box>
                ) : (

                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Typography variant="h6" align="center" sx={{mt: 4}}
                                    color="text.secondary">
                            Loading Event Details
                        </Typography>
                        <CircularProgress/>
                    </Box>
                )}
            </Container>
        </Box>
    );
};

const MainFighterProfile = ({ fighter, onClick }) => {
    return (
        <Box
            onClick={() => onClick(fighter.displayName)}
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            justifyContent="flex-start"
            sx={{
                flex: 1,
                p: 2,
                '&:hover': {
                    cursor: 'pointer',
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                },
            }}
        >
            <Avatar
                src={fighter.flag.href || 'default-flag-url'}
                alt={fighter.flag.alt}
                sx={{ width: 60, height: 60, mb: 1, borderRadius: 6 }}
            />

            {fighter.headshot ? (
                <Avatar
                    src={fighter.headshot.href}
                    alt={fighter.headshot.alt}
                    sx={{ width: 100, height: 100, mb: 1, borderRadius: 4 }}
                />
            ) : (
                <Avatar
                    src={UnknownFighterHeadshot}
                    alt={"Unknown Fighter Headshot"}
                    sx={{ width: 75, height: 75, mb: 1, borderRadius: 4 }}
                />
            )
            }
            <Typography color="text.primary" variant="h4" component="div">
                {fighter.fullName}
            </Typography>

            {fighter.nickname ? (
                <Typography color="text.primary" variant="h7" component="div">
                    ({fighter.nickname})
                </Typography>
                ) : (
                <Typography color="text.primary" variant="h7" component="div">
                    ({fighter.lastName})
                </Typography>
            )}


            <Typography variant="body2" color="text.secondary">
                {fighter.statsSummary.statistics[0].displayValue}
            </Typography>

            <Box sx={{ mt: 2, width: '100%' }}>

                <Box sx={{ marginBottom: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', p: 1, bgcolor: 'background.paper', borderRadius: 2 }}>
                    <Typography variant="body2" color="text.secondary" align="center">
                        Age:
                    </Typography>
                    <Typography variant="h6" color="text.primary" align="center">
                        {fighter.age}
                    </Typography>
                </Box>

                <Box sx={{ marginBottom: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', p: 1, bgcolor: 'background.paper', borderRadius: 2 }}>
                    <Typography variant="body2" color="text.secondary" align="center">
                        Height:
                    </Typography>
                    <Typography variant="h6" color="text.primary" align="center">
                        {fighter.displayHeight}
                    </Typography>
                </Box>

                <Box sx={{ marginBottom: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', p: 1, bgcolor: 'background.paper', borderRadius: 2 }}>
                    <Typography variant="body2" color="text.secondary" align="center">
                        Weight:
                    </Typography>
                    <Typography variant="h6" color="text.primary" align="center">
                        {fighter.displayWeight}
                    </Typography>
                </Box>

                <Box sx={{ marginBottom: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', p: 1, bgcolor: 'background.paper', borderRadius: 2 }}>
                    <Typography variant="body2" color="text.secondary" align="center">
                        Reach:
                    </Typography>
                    <Typography variant="h6" color="text.primary" align="center">
                        {fighter.displayReach}
                    </Typography>
                </Box>

                <Box sx={{ marginBottom: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', p: 1, bgcolor: 'background.paper', borderRadius: 2 }}>
                    <Typography variant="body2" color="text.secondary" align="center">
                        Style:
                    </Typography>
                    <Typography variant="h6" color="text.primary" align="center">
                        {fighter.displayFightingStyle}
                    </Typography>
                </Box>

                <Box sx={{ marginBottom: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', p: 1, bgcolor: 'background.paper', borderRadius: 2 }}>
                    <Typography variant="body2" color="text.secondary" align="center">
                        Stance:
                    </Typography>
                    <Typography variant="h6" color="text.primary" align="center">
                        {fighter.stance.text}
                    </Typography>
                </Box>

            </Box>
        </Box>
    );
};





const HeroSection = () => {

    const navigate = useNavigate();
    const onExploreClick = () => {
        navigate('/rankings');
    };

    return (
        <Box
            sx={{
                backgroundImage: 'url(https://example.com/your-image.jpg)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                color: 'primary.contrastText',
                py: 10,
                textAlign: 'center',
                backgroundColor: 'primary.main',
                paddingBottom: 15,
                paddingTop: 15,
            }}
        >
            <Container maxWidth="md">
                <Typography variant="h2" gutterBottom color="secondary.main">
                    Welcome to UFC Stats Hub
                </Typography>
                <Typography variant="h5" gutterBottom color="text.primary">
                    Your go-to source for all UFC events and fighter statistics.
                </Typography>
                <Button onClick={onExploreClick} variant="contained" color="secondary" size="large"
                        sx={{mt: 4}}>
                    Explore Rankings
                </Button>
            </Container>
        </Box >
    );
};

export default LandingPage;
