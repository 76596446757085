import axios from 'axios';


const mmaApiKey = 'c81b39aea2msh876f66e2467cbe9p14b42cjsne818161970f0'


export const getAllEvents = async () => {
    const options = {
        method: 'GET',
        url: 'https://mma-api1.p.rapidapi.com/getEventId',
        params: { year: '2024' },
        headers: {
            'x-rapidapi-key': mmaApiKey,
            'x-rapidapi-host': 'mma-api1.p.rapidapi.com'
        }
    };

    try {
        return await axios.request(options)
    } catch(error){
        console.log("Error fetching all events: ", error);
    }
};

export const getEventIds = async () => {
    const options = {
        method: 'GET',
        url: 'https://mma-api1.p.rapidapi.com/getEventId',
        params: { year: '2024' },
        headers: {
            'x-rapidapi-key': mmaApiKey,
            'x-rapidapi-host': 'mma-api1.p.rapidapi.com'
        }
    };

    try {
        const response = await axios.request(options)


        // Step 1: Convert start date to Date object and map events
        const eventList = response.data.result.map(event => ({
            ...event,
            startDate: new Date(event.startDate) // Convert startDate to Date object if needed
        }));

        // Step 2: Filter out non-numbered UFC events
        const ufcEvents = eventList.filter(event => event.event.startsWith('UFC'));

        // Step 3: Sort events by date
        const sortedUFCEvents = ufcEvents.sort((a, b) => a.startDate - b.startDate);

        // Step 4: Identify previous, current, and next events
        const today = new Date();
        const nextEventIndex = sortedUFCEvents.findIndex(event => event.startDate >= today);

        if (nextEventIndex !== -1) {
            const previousEvent = sortedUFCEvents[nextEventIndex - 1] || null;
            const currentEvent = sortedUFCEvents[nextEventIndex] || null;
            const nextEvent = sortedUFCEvents[nextEventIndex + 1] || null;
            const events = {
                previousEventId: previousEvent ? previousEvent.eventId : null,
                currentEventId: currentEvent ? currentEvent.eventId : null,
                nextEventId: nextEvent ? nextEvent.eventId : null,
            }

            return events;
        }


        } catch(error){
        console.log("Error fetching all events: ", error);
    }
}


export const getEventDetails = async (eventId) => {
    const options = (eventId) => ({
        method: 'GET',
        url: 'https://mma-api1.p.rapidapi.com/scoreboard-by-event',
        params: {eventId: eventId},
        headers: {
            'x-rapidapi-key': mmaApiKey,
            'x-rapidapi-host': 'mma-api1.p.rapidapi.com'
        }
    });

    try {
        const response = await axios.request(options(eventId));
        return response.data;
    } catch(error){
        console.log("Error fetching event ID ", eventId, " details: ", error);
    }
}

export const searchFighterId = async (playerName) => {
    const options = {
        method: 'GET',
        url: 'https://mma-api1.p.rapidapi.com/search',
        params: { query: playerName },
        headers: {
            'x-rapidapi-key': mmaApiKey,
            'x-rapidapi-host': 'mma-api1.p.rapidapi.com'
        }
    };

    try {
        const response = await axios.request(options);
        const players = response.data.players;

        if(!players) {
            console.log("fighter not found");
            return null;
        }

        // Find the player that matches the exact name
        //const player = players.find(p => p.displayName.toLowerCase() === playerName.toLowerCase());
        const player = players[0];

        if (player) {
            return player.fighterId;
        } else {
            console.log('Player not found');
            return null;
        }
    } catch (error) {
        console.error('Error fetching player data:', error);
        return null;
    }
};

export const getFighterDetails = async (fighterId) => {
    const requestOptions = {
        method: 'GET',
        url: `https://mma-api1.p.rapidapi.com/player-details`,
        params: {
            fighterId: fighterId
        },
        headers: {
            'x-rapidapi-key': 'c81b39aea2msh876f66e2467cbe9p14b42cjsne818161970f0',
            'x-rapidapi-host': 'mma-api1.p.rapidapi.com'
        }
    };

    try {
        const response = await axios.request(requestOptions);
        return response.data.fighter_full_detail;
    } catch(error) {
        console.log('Error fetching fighter details', error);
    }
};


export const getDivisionalRanking = async() => {
    const options = {
        method: 'GET',
        url: 'https://mma-api1.p.rapidapi.com/drankings',
        headers: {
            'x-rapidapi-key': mmaApiKey,
            'x-rapidapi-host': 'mma-api1.p.rapidapi.com'
        }
    };

    try {
        return await axios.request(options);
    } catch (error) {
        console.log('Error fetching divisional ranking data: ', error);
        return null;
    }
};