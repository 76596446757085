import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../Components/Header';
import Footer from "../Components/Footer";
import {Box, GlobalStyles} from "@mui/material";

const Layout = ({ token, setToken }) => {
    return (
        <>
            <GlobalStyles
                styles={{
                    body: {
                        margin: 0,
                        padding: 0,
                        backgroundColor: 'primary.main',
                    },
                    '*': {
                        boxSizing: 'border-box',
                    },
                }}
            />
            <Box sx={{ bgcolor: 'primary.main', minHeight: '100vh' }}>
                <Header />
                <Outlet />
                <Footer />
            </Box>
        </>
    );
};

export default Layout;