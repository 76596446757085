import React, { useState } from "react";
import { Box, Container, Typography, InputBase, IconButton, Grid } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from "react-router-dom";

const SearchPage = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    const handleSearch = () => {
        if (searchTerm.trim() !== '') {
            const encodedSearchTerm = encodeURIComponent(searchTerm);
            navigate(`/fighter/${encodedSearchTerm}`);
        }
    };

    return (
        <Container maxWidth="sm" sx={{ minHeight: '100vh', padding: 2 }}>
            <Typography
                variant="h4"
                component="h1"
                color="text.primary"
                align="center"
                gutterBottom
                sx={{ marginTop: 4 }}
            >
                Search Fighters
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 1,
                    padding: '8px 16px',
                    mb: 3,
                    width: '100%',
                }}
            >
                <InputBase
                    placeholder="Search UFC fighters"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    fullWidth
                    sx={{ ml: 1, flex: 1 }}
                />
                <IconButton type="button" sx={{ p: '10px' }} onClick={handleSearch}>
                    <SearchIcon />
                </IconButton>
            </Box>

            <Grid container spacing={2} justifyContent="center">
                {/* This area can show recent searches or featured fighters for UX enhancement */}
                <Grid item xs={12}>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography variant="body2" color="text.secondary">
                            Search for a fighter by name or nickname.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default SearchPage;
