import {
    AppBar,
    Box,
    Button,
    Toolbar,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    ButtonBase
} from "@mui/material";
import SearchBar from "./SearchBar";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from '@mui/icons-material/Menu';

const Header = () => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const routeToPage = (path) => {
        handleMenuClose();
        navigate(path);
    };

    const onSearch = (searchTerm) => {
        const encodedSearchTerm = encodeURIComponent(searchTerm);
        navigate(`/fighter/${encodedSearchTerm}`);
    };

    return (
        <AppBar position="static" sx={{ mb: 4, bgcolor: 'primary.main' }}>
            <Toolbar sx={{ justifyContent: 'space-between' }}>

                <ButtonBase onClick={() => routeToPage("/")}>
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        UFC Stats Hub
                    </Typography>
                </ButtonBase>

                {!isMobile && (
                    <Box sx={{ mx: 4, flexGrow: 1 }}>
                        <SearchBar onSearch={onSearch} />
                    </Box>
                )}

                {isMobile ? (
                    <>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={handleMenuOpen}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={() => routeToPage('/')}>Home</MenuItem>
                            <MenuItem onClick={() => routeToPage('/search')}>Search</MenuItem>
                            <MenuItem onClick={() => routeToPage('/rankings')}>Rankings</MenuItem>
                            <MenuItem onClick={() => routeToPage('/events')}>Upcoming Events</MenuItem>
                        </Menu>
                    </>
                ) : (
                    <>
                        <Button color="inherit" onClick={() => routeToPage('/')}>Home</Button>
                        <Button color="inherit" onClick={() => routeToPage('/rankings')}>Rankings</Button>
                        <Button color="inherit" onClick={() => routeToPage('/events')}>Upcoming Events</Button>
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Header;
