import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import {
    Box,
    Typography,
    Avatar,
    CircularProgress,
    Divider,
} from '@mui/material';
import { getFighterDetails } from '../API';
import UnknownFighterHeadshot from '../Resources/Unknown_Fighter.png';

const CACHE_KEY = 'FighterDetailsCache';
const CACHE_EXPIRY = 24 * 60 * 60 * 1000; // 24 hours

function FightPage() {
    const { fighter1Id, fighter2Id } = useParams(); // Get fighter IDs from URL params
    const location = useLocation();
    const { fight } = location.state || {}; // Access fight data from navigation state

    const [fighter1, setFighter1] = useState(null);
    const [fighter2, setFighter2] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);

    const navigate = useNavigate();

    const cacheFighterData = (fighterData) => {
        const cachedData = localStorage.getItem(CACHE_KEY);
        let fighters = [];

        if (cachedData) {
            const { fighters: existingFighters } = JSON.parse(cachedData);
            fighters = existingFighters;
        }

        // Add or update the fighter data
        const updatedFighters = [
            ...fighters.filter(
                (fighter) => fighter.athlete.id !== fighterData.athlete.id
            ),
            fighterData,
        ];

        // Store the updated array with a new timestamp
        localStorage.setItem(
            CACHE_KEY,
            JSON.stringify({
                fighters: updatedFighters,
                timestamp: Date.now(),
            })
        );
    };

    const checkCachedFighterData = (fighterId) => {
        const cachedData = localStorage.getItem(CACHE_KEY);

        if (cachedData) {
            const { fighters, timestamp } = JSON.parse(cachedData);

            // Check if the cache is older than 24 hours
            const ONE_DAY = 24 * 60 * 60 * 1000;
            if (Date.now() - timestamp < ONE_DAY) {
                const cachedFighter = fighters.find(
                    (fighter) => fighter.athlete.id === fighterId
                );

                if (cachedFighter) {
                    return cachedFighter;
                }
            }
        }
        return null; // Data not found or expired
    };

    useEffect(() => {
        const fetchFighterData = async (fighterId, setFighter) => {
            try {
                const cachedFighter = checkCachedFighterData(fighterId);
                if (cachedFighter) {
                    console.log(`Using cached data for fighter ${fighterId}`);
                    setFighter(cachedFighter.athlete);
                } else {
                    console.log(`Fetching data for fighter ${fighterId}`);
                    const fighterDetails = await getFighterDetails(fighterId);
                    if (fighterDetails && fighterDetails.athlete) {
                        setFighter(fighterDetails.athlete);
                        cacheFighterData(fighterDetails);
                    } else {
                        console.log(`Fighter ${fighterId} not found`);
                        setNotFound(true);
                    }
                }
            } catch (error) {
                console.error(`Error fetching data for fighter ${fighterId}:`, error);
                setNotFound(true);
            }
        };

        const fetchData = async () => {
            if (!fighter1Id || !fighter2Id) {
                console.log('Error: Fighter IDs not provided.');
                return;
            }

            await Promise.all([
                fetchFighterData(fighter1Id, setFighter1),
                fetchFighterData(fighter2Id, setFighter2),
            ]);

            setIsLoading(false);
        };

        fetchData();
    }, [fighter1Id, fighter2Id]);

    const switchFighter = (name) => {
        const encodedSearchTerm = encodeURIComponent(name);
        navigate(`/fighter/${encodedSearchTerm}`);
    };

    const renderFighterProfile = (fighter) => {
        if (!fighter) return null;

        return (
            <Box
                onClick={() => switchFighter(fighter.displayName)}
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
                justifyContent="flex-start"
                sx={{
                    flex: 1,
                    p: 2,
                    '&:hover': {
                        cursor: 'pointer',
                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                        transition: 'background-color 0.3s ease',
                    },
                }}
            >
                <Avatar
                    src={fighter.flag?.href || UnknownFighterHeadshot}
                    alt={fighter.flag?.alt || 'Country Flag'}
                    sx={{ width: 60, height: 60, mb: 1, borderRadius: 6 }}
                />

                {fighter.headshot ? (
                    <Avatar
                        src={fighter.headshot.href}
                        alt={fighter.headshot.alt}
                        sx={{ width: 100, height: 100, mb: 1, borderRadius: 4 }}
                    />
                ) : (
                    <Avatar
                        src={UnknownFighterHeadshot}
                        alt="Unknown Fighter Headshot"
                        sx={{ width: 75, height: 75, mb: 1, borderRadius: 4 }}
                    />
                )}

                <Typography color="text.primary" variant="h4" component="div">
                    {fighter.fullName}
                </Typography>

                {fighter.nickname ? (
                    <Typography color="text.primary" variant="h7" component="div">
                        ({fighter.nickname})
                    </Typography>
                ) : (
                    <Typography color="text.primary" variant="h7" component="div">
                        ({fighter.lastName})
                    </Typography>
                )}

                <Typography variant="body2" color="text.secondary">
                    {fighter.statsSummary?.statistics[0]?.displayValue || 'N/A'}
                </Typography>

                <Box sx={{ mt: 2, width: '100%' }}>
                    {/* Render fighter stats */}
                    {[
                        { label: 'Age', value: fighter.age },
                        { label: 'Height', value: fighter.displayHeight },
                        { label: 'Weight', value: fighter.displayWeight },
                        { label: 'Reach', value: fighter.displayReach },
                        { label: 'Style', value: fighter.displayFightingStyle },
                        { label: 'Stance', value: fighter.stance?.text },
                    ].map((stat, index) => (
                        <Box
                            key={index}
                            sx={{
                                marginBottom: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                p: 1,
                                bgcolor: 'background.paper',
                                borderRadius: 2,
                            }}
                        >
                            <Typography variant="body2" color="text.secondary" align="center">
                                {stat.label}:
                            </Typography>
                            <Typography variant="h6" color="text.primary" align="center">
                                {stat.value || 'N/A'}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        );
    };

    // Function to determine the winner
    const determineWinner = () => {
        if (!fight || !fight.status) return null;

        if (!fight.status.type.completed) {
            return 'Scheduled';
        }

        const competitor1 = fight.competitors?.[0];
        const competitor2 = fight.competitors?.[1];

        if (competitor1?.winner && !competitor2?.winner) {
            return fighter1?.fullName || 'Fighter 1';
        }

        if (competitor2?.winner && !competitor1?.winner) {
            return fighter2?.fullName || 'Fighter 2';
        }

        return 'Draw';
    };

    if (isLoading) {
        return (
            <Box sx={{ bgcolor: 'primary.main', minHeight: '100vh', padding: 2 }}>
                <Typography
                    variant="h5"
                    component="div"
                    color="text.primary"
                    gutterBottom
                >
                    Fetching Fighter Data
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                    Please wait while we load the fighters' information.
                </Typography>
                <Box
                    mt={2}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress sx={{ color: 'primary.light' }} />
                </Box>
            </Box>
        );
    }

    if (notFound || !fighter1 || !fighter2) {
        return (
            <Box sx={{ bgcolor: 'primary.main', minHeight: '100vh', padding: 2 }}>
                <Typography
                    variant="h5"
                    component="div"
                    color="error.main"
                    gutterBottom
                >
                    Fighters Not Found
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                    We couldn't find information about one or both fighters.
                </Typography>
            </Box>
        );
    }

    const winner = determineWinner();

    return (
        <Box sx={{ bgcolor: 'primary.main', minHeight: '100vh', padding: 2 }}>
            <Typography
                variant="h4"
                component="h1"
                color="text.primary"
                align="center"
                gutterBottom
            >
                Fight Comparison
            </Typography>

            {/* Display Fight Status and Winner */}
            <Box
                sx={{
                    mb: 4,
                    textAlign: 'center',
                }}
            >
                <Typography variant="h6" color="text.secondary">
                    {fight.status?.type?.name || 'Status Unknown'}
                </Typography>
                {fight.status?.type?.completed && (
                    <Typography variant="h5" color="success.main" sx={{ mt: 1 }}>
                        Winner: {winner}
                    </Typography>
                )}
                {!fight.status?.type?.completed && (
                    <Typography variant="h5" color="warning.main" sx={{ mt: 1 }}>
                        Upcoming Fight
                    </Typography>
                )}
            </Box>


            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                }}
            >
                {/* Fighter 1 Profile */}
                {renderFighterProfile(fighter1)}

                {/* Versus Text */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 2,
                    }}
                >
                    <Typography variant="h4" color="text.primary">
                        VS
                    </Typography>
                </Box>

                {/* Fighter 2 Profile */}
                {renderFighterProfile(fighter2)}
            </Box>

            {/* Additional Fight Details */}
            {fight.status?.type?.completed && (
                <Box
                    sx={{
                        mt: 4,
                        p: 2,
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: 1,
                        maxWidth: 600,
                        margin: 'auto',
                    }}
                >
                    <Typography variant="h6" color="text.primary" gutterBottom>
                        Fight Details
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    <Typography variant="body1" color="text.secondary" sx={{ mb: 1 }}>
                        <strong>Coming Soon!</strong>
                    </Typography>
                </Box>
            )}
        </Box>
    );

}

export default FightPage;
