import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography, Grid, Divider, Collapse, IconButton } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import {useNavigate} from "react-router-dom";

const RankingCard = ({ rank }) => {
    const [divisionName, setName] = useState(null);
    const [divisionDescription, setDescription] = useState(null);
    const [openFighters, setOpenFighters] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        // Splits LIGHT HEAVYWEIGHT (up to 205 pounds) into name and description
        const regex = /^([^\(]+)\s*(\([^)]+\))$/;  // Match everything before the '(' and inside the '()'
        const match = rank.division.match(regex);

        if (match) {
            setName(match[1].trim());
            setDescription(match[2].trim());
        } else {
            setName(rank.division);
            setDescription("Unknown WeightClass");
        }
    }, [rank]);

    const handleToggle = (index) => {
        setOpenFighters((prevOpenFighters) => ({
            ...prevOpenFighters,
            [index]: !prevOpenFighters[index],
        }));
    };

    const searchFighter = (fighterName) => {
        const encodedSearchTerm = encodeURIComponent(fighterName);
        navigate(`/fighter/${encodedSearchTerm}`);
    };

    return (
        <Card sx={{ minWidth: 275, mb: 2, bgcolor: '#333', color: '#fff', borderRadius: 2, boxShadow: 3 }}>
            <CardContent>
                <Typography variant="h5" component="div" gutterBottom sx={{ color: 'text.primary', fontWeight: 'bold' }}>
                    {divisionName}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" gutterBottom sx={{ color: '#aaa' }}>
                    {divisionDescription}
                </Typography>
                <Divider sx={{ my: 4, bgcolor: 'text.secondary' }} />

                <Grid container direction="column" spacing={2}>
                    {rank.fighters.map((fighter, index) => (
                        <Grid item key={index}>
                            <Box
                                onClick={() => searchFighter(fighter.fighter.name)}
                                mb={2}
                                sx={{
                                    backgroundColor: 'background.paper',
                                    borderRadius: 2.5,
                                    p: 2,
                                    transition: 'background-color 0.3s ease',  // Smooth transition
                                    '&:hover': {
                                        backgroundColor: '#555',
                                        cursor: 'pointer',
                                        transform: 'scale(1.02)',
                                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
                                    },
                                }}
                            >
                                <Grid container direction="row" alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <Typography>
                                            {fighter.rank} | {fighter.fighter.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            {fighter.record}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default RankingCard;
