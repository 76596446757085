import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function NoPage() {
    const navigate = useNavigate();

    const handleGoHome = () => {
        navigate('/');
    };

    return (
        <Container
            maxWidth={false}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                textAlign: 'center',
                bgcolor: 'background.default',
                color: 'text.primary'
            }}
        >
            <Typography variant="h1" gutterBottom sx={{ color: 'error.main' }}>
                ERROR 404
            </Typography>
            <Typography variant="h5" gutterBottom>
                Oops! The page you're looking for doesn't exist.
            </Typography>
            <Box mt={4}>
                <Button
                    variant="contained"
                    sx={{
                        bgcolor: 'var(--background-color-accent)',
                        '&:hover': {
                            bgcolor: 'var(--accent-color-one)'
                        }
                    }}
                    onClick={handleGoHome}
                >
                    Get Back Home
                </Button>
            </Box>
        </Container>
    );
}

export default NoPage;
