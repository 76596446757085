import React, { useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    Typography,
    Grid,
    Divider,
    Avatar,
    Button,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';

const EventCard = ({ event }) => {
    const navigate = useNavigate();
    const [showAllFights, setShowAllFights] = useState(false);

    if (!event) return null;

    const handleFightClick = (fighter1Id, fighter2Id, fight) => {
        if (fighter1Id && fighter2Id) {
            navigate(`/fight/${fighter1Id}/${fighter2Id}`, { state: { fight } });
        } else {
            console.warn('Fighter IDs are undefined:', fighter1Id, fighter2Id);
        }
    };

    // Helper function to format the date
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    // Toggle function for showing all fights
    const toggleShowAllFights = () => {
        setShowAllFights((prevShowAllFights) => !prevShowAllFights);
    };

    // Determine which fights to display, with the last ones first
    const fightsToDisplay = showAllFights
        ? [...(event.competitions || [])].reverse() // Reverse the entire array when showing all fights
        : event.competitions?.slice(-3).reverse(); // Reverse only the last 3 fights when not showing all

    return (
        <Card
            sx={{
                minWidth: 275,
                mb: 2,
                bgcolor: '#333',
                color: '#fff',
                borderRadius: 2,
                boxShadow: 3,
            }}
        >
            <CardContent>
                <Typography
                    variant="h5"
                    component="div"
                    gutterBottom
                    sx={{ color: '#fff', fontWeight: 'bold' }}
                >
                    {event.name || 'Unknown Event'}
                </Typography>
                <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    gutterBottom
                    sx={{ color: '#aaa' }}
                >
                    {event.date ? formatDate(event.date) : 'Unknown Date'} -{' '}
                    {event.venue?.fullName}, {event.venue?.address?.city}
                </Typography>
                <Divider sx={{ my: 4, bgcolor: '#444' }} />

                {fightsToDisplay?.length > 0 ? (
                    fightsToDisplay.map((fight, index) => {
                        const competitor1 = fight.competitors?.[0];
                        const competitor2 = fight.competitors?.[1];
                        const fighter1 = competitor1?.athlete;
                        const fighter2 = competitor2?.athlete;

                        console.log('Competitor 1:', competitor1);
                        console.log('Competitor 2:', competitor2);

                        if (!competitor1 || !competitor2 || !fighter1 || !fighter2) {
                            console.warn('Missing competitor data for fight:', fight);
                            return null;
                        }

                        return (
                            <Box
                                onClick={() => handleFightClick(competitor1.id, competitor2.id, fight)}
                                key={index}
                                mb={2}
                                sx={{
                                    backgroundColor: '#444',
                                    borderRadius: 1,
                                    p: 2,
                                    transition: 'transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease',
                                    '&:hover': {
                                        backgroundColor: '#555',
                                        cursor: 'pointer',
                                        transform: 'scale(1.02)',
                                        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
                                    },
                                }}
                            >
                                <Grid container spacing={2} alignItems="center">
                                    {/* Fighter 1 */}
                                    <Grid
                                        item
                                        xs={5}
                                        container
                                        direction="column"
                                        alignItems="center"
                                    >
                                        {competitor1?.winner === true && (
                                            <Typography variant="h6" color="success">
                                                Winner
                                            </Typography>
                                        )}
                                        <Avatar
                                            src={fighter1.flag?.href || 'default-flag-url'}
                                            alt={fighter1.fullName}
                                            sx={{ width: 56, height: 56 }}
                                        />
                                        <Typography
                                            variant="h6"
                                            component="div"
                                            color="text.primary"
                                            sx={{ ml: 0, textAlign: 'center' }}
                                        >
                                            {fighter1.fullName}
                                        </Typography>

                                        <Typography
                                            variant="body1"
                                            component="div"
                                            color="text.secondary"
                                            sx={{ ml: 0, textAlign: 'center' }}
                                        >
                                            {competitor1?.records?.[0]?.summary ||
                                                'No record available'}
                                        </Typography>
                                    </Grid>

                                    {/* VS */}
                                    <Grid item xs={2}>
                                        <Typography
                                            variant="h6"
                                            align="center"
                                            sx={{ color: '#aaa' }}
                                        >
                                            vs
                                        </Typography>
                                    </Grid>

                                    {/* Fighter 2 */}
                                    <Grid
                                        item
                                        xs={5}
                                        container
                                        direction="column"
                                        alignItems="center"
                                    >
                                        {competitor2?.winner === true && (
                                            <Typography variant="h6" color="success">
                                                Winner
                                            </Typography>
                                        )}
                                        <Avatar
                                            src={fighter2.flag?.href || 'default-flag-url'}
                                            alt={fighter2.fullName}
                                            sx={{ width: 56, height: 56 }}
                                        />
                                        <Typography
                                            variant="h6"
                                            component="div"
                                            color="text.primary"
                                            sx={{ mr: 0, textAlign: 'center' }}
                                        >
                                            {fighter2.fullName}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            component="div"
                                            color="text.secondary"
                                            sx={{ ml: 0, textAlign: 'center' }}
                                        >
                                            {competitor2?.records?.[0]?.summary ||
                                                'No record available'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    align="center"
                                    sx={{ mt: 1, color: '#ccc' }}
                                >
                                    {fight.type?.abbreviation || 'N/A'}
                                </Typography>

                                {fight.status?.type?.completed === true && (
                                    <CheckCircleIcon
                                        color="success"
                                        sx={{ margin: 'auto', display: 'block' }}
                                    />
                                )}
                                {index < fightsToDisplay.length - 1 && (
                                    <Divider sx={{ my: 2, bgcolor: '#444' }} />
                                )}
                            </Box>
                        );
                    })
                ) : (
                    <Typography>No fights available.</Typography>
                )}

                {event.competitions?.length > 3 && (
                    <Button
                        onClick={toggleShowAllFights}
                        variant="text"
                        sx={{ mt: 2, color: '#fff', textTransform: 'none' }}
                    >
                        {showAllFights ? 'Show Less' : 'Show All Fights'}
                    </Button>
                )}
            </CardContent>
        </Card>
    );
};

export default EventCard;
