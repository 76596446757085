import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useNavigate, useParams} from 'react-router-dom';
import {
    Card,
    CardContent,
    CardMedia,
    Typography,
    Box,
    Link,
    CircularProgress,
    Avatar,
    Divider,
    Collapse,
} from '@mui/material';
import {getFighterDetails, searchFighterId} from "../API";

import UnknownFighterHeadshot from "../Resources/Unknown_Fighter.png";

const CACHE_KEY = 'FighterDetailsCache';
const CACHE_EXPIRY = 24 * 60 * 60 * 1000; // 24 hours


function FighterPage() {
    const {name: encodedName} = useParams(); // Get the encoded search term from the URL
    const name = decodeURIComponent(encodedName || ''); // Decode the search term if necessary
    const [fighter, setFighter] = useState(null);
    const [playerSwitcher, setPlayerSwitcher] = useState(null);
    const [ticketInfo, setTicketInfo] = useState(null);
    const [notFound, setNotFound] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    const navigate = useNavigate();

    const switchFighter = (name) => {
        const encodedSearchTerm = encodeURIComponent(name);
        //See which is better
        //navigate(`/fighter/${encodedSearchTerm}`);
        window.location.href = `/fighter/${encodedSearchTerm}`;
    };

    const toggleExpander = () => {
        setIsExpanded(!isExpanded);
    }

    const getFighterId = async (query) => {
      try {
          const fighterId = await searchFighterId(query);

          return fighterId ? fighterId : null;
      }  catch  (error){
          console.log("Error: failed to get fighter id: ", error);
          return null;
      }
    };

    // // Function to search for the fighter by name
    // const getFighterStats = async (fighterId) => {
    //     try {
    //         const fighterDetails = await getFighterDetails(fighterId);
    //         console.log("Data: ", fighterDetails);
    //         return fighterDetails ? fighterDetails : null;
    //
    //     } catch (error) {
    //         console.error("Error searching fighter:", error);
    //         return null
    //     }
    // };

    const cacheFighterData = (fighterData) => {
        const cachedData = localStorage.getItem(CACHE_KEY);
        let fighters = [];

        if (cachedData) {
            const { fighters: existingFighters } = JSON.parse(cachedData);
            fighters = existingFighters;
        }

        // Add or update the fighter data
        const updatedFighters = [...fighters.filter(fighter => fighter.athlete.id !== fighterData.athlete.id), fighterData];

        // Store the updated array with a new timestamp
        localStorage.setItem(CACHE_KEY, JSON.stringify({
            fighters: updatedFighters,
            timestamp: Date.now()
        }));
    };


    const checkCachedFighterData = (searchValue, type = 'id') => {
        const cachedData = localStorage.getItem(CACHE_KEY);

        if (cachedData) {
            const { fighters, timestamp } = JSON.parse(cachedData);

            // Check if the cache is older than 24 hours
            const ONE_DAY = 24 * 60 * 60 * 1000;
            if (Date.now() - timestamp < ONE_DAY) {
                // Search by id or displayName based on type
                const cachedFighter = fighters.find(fighter => {
                    if (type === 'id') {
                        return fighter.athlete.id === searchValue;
                    } else if (type === 'displayName') {
                        return fighter.athlete.displayName.toLowerCase() === searchValue.toLowerCase();
                    }
                    return false;
                });

                if (cachedFighter) {
                    return cachedFighter;
                }
            }
        }
        return null; // Data not found or expired
    };



    useEffect(() => {
        const fetchData = async () => {
            if (!name) {
                console.log("Error: Name not provided.");
                return;
            }

            try {
                const cacheCheckOne = checkCachedFighterData(name, 'displayName');
                if(cacheCheckOne) {
                    console.log("Using cached fighter data from name...");
                    setFighter(cacheCheckOne.athlete);
                    setPlayerSwitcher(cacheCheckOne.playerSwitcher.athletes);
                    return;
                }

                const fighterId = await getFighterId(name);

                if(!fighterId){
                    setNotFound(true);
                    return;
                }


                console.log('fighterID: ', fighterId);

                // Check if the data is cached
                const cacheCheckTwo = checkCachedFighterData(fighterId);

                if (cacheCheckTwo) {
                    console.log("Using cached fighter data...");
                    setFighter(cacheCheckTwo.athlete);
                    setPlayerSwitcher(cacheCheckTwo.playerSwitcher.athletes);
                } else {
                    console.log("Fetching new fighter data...");
                    const fighterDetails = await getFighterDetails(fighterId);
                    console.log("Fighter: ", fighterDetails);

                    setFighter(fighterDetails.athlete);
                    setPlayerSwitcher(fighterDetails.playerSwitcher.athletes);

                    // Cache the new data
                    cacheFighterData(fighterDetails);
                }
            } catch (error) {
                console.error("Error fetching fighter data:", error);
            }
        };

        fetchData();

    }, [name]);


    return (
        <Box sx={{bgcolor: 'primary.main', minHeight: '100vh', padding: 2}}>
            <Typography variant="h4" component="h1" color="text.primary"
                        align="center" gutterBottom>
                Fighter Info
            </Typography>
            {fighter ? (
                <Box >
                    <Card sx={{
                        maxWidth: 600,
                        margin: 'auto',
                        mt: 4,
                        bgcolor: 'primary.main',
                        border: 1,
                        boxShadow: '0px 2px 8px rgba(211, 47, 47, 0.5)',
                        borderColor: 'primary.light'
                    }}>

                        {fighter.headshot ? (
                            <CardMedia
                                component="img"
                                height="300"
                                image={fighter.headshot.href}
                                alt={fighter.headshot.alt || 'Fighter Headshot'}
                            />
                        ) : (
                            <CardMedia
                                component="img"
                                height="300"
                                image={UnknownFighterHeadshot}
                                alt={'Unknown Fighter Headshot'}
                            />
                        )}

                        <CardContent>
                            <Box display="flex" alignItems="center">
                                <Typography variant="h5" component="span"
                                            color="text.primary"
                                            sx={{marginRight: 1}}>
                                    {fighter.fullName}
                                </Typography>
                                <Avatar
                                    src={fighter.flag.href}
                                    alt={fighter.flag.alt}
                                    sx={{width: 40, height: 40}}
                                />
                            </Box>
                            <Typography variant="h7" component="span"
                                        color="text.secondary">
                                '{fighter.nickname}'
                            </Typography>
                            {fighter.weightClass && (
                                <Typography variant="body2" color="text.secondary"
                                            gutterBottom>
                                    {fighter.displayFightingStyle} | {fighter.weightClass.text} | {fighter.stance.text}
                                </Typography>
                            )}


                            <Box mt={2}>
                                <Typography variant="body1" color="text.primary"
                                            gutterBottom>
                                    <strong>Height:</strong> {fighter.displayHeight}
                                </Typography>
                                <Typography variant="body1" color="text.primary"
                                            gutterBottom>
                                    <strong>Weight:</strong> {fighter.displayWeight}
                                </Typography>
                                <Typography variant="body1" color="text.primary"
                                            gutterBottom>
                                    <strong>Reach:</strong> {fighter.displayReach}
                                </Typography>
                                <Typography variant="body1" color="text.primary"
                                            gutterBottom>
                                    <strong>Age:</strong> {fighter.age}
                                </Typography>
                                <Typography variant="body1" color="text.primary"
                                            gutterBottom>
                                    <strong>Citizenship:</strong> {fighter.citizenship}
                                </Typography>
                                <Typography variant="body1" color="text.primary"
                                            gutterBottom>
                                    <strong>Status:</strong> {fighter.status.name}
                                </Typography>
                                <Typography variant="body1" color="text.primary"
                                            gutterBottom>
                                    <strong>Association:</strong> {fighter.association.name}
                                </Typography>
                            </Box>


                            <Box mt={2}>
                                <Typography variant="h5" color="text.primary" gutterBottom>
                                    Fight Stats
                                </Typography>

                                {fighter.statsSummary?.statistics.map((stat, index) => (

                                    <Box
                                        key={index}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            backgroundColor: 'primary.light',
                                            padding: 2,
                                            borderRadius: '8px',
                                            mb: 2,
                                            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                                            //maxWidth: 250
                                        }}
                                    >
                                        {/* Stat label */}
                                        <Typography variant="body1" color="text.primary" sx={{ fontWeight: 'bold' }}>
                                            {stat.shortDisplayName}:
                                        </Typography>

                                        {/* Stat value */}
                                        <Typography variant="body1" color="text.secondary">
                                            {stat.displayValue}
                                        </Typography>
                                    </Box>

                                ))}
                            </Box>


                            <Box mt={2} textAlign='center'>
                                <Typography
                                    onClick={toggleExpander}
                                    variant="p2"
                                    color="text.primary"
                                    sx={{
                                        mt: 10,
                                        '&:hover': {
                                            cursor: 'pointer',
                                            color: 'secondary.main'
                                        }}}>
                                    More Info ▼
                                </Typography>

                                <Collapse in={isExpanded} sx={{mt:2}}>
                                    {fighter.links?.map((link, index) => (
                                        <Typography key={index} variant="body2"
                                                    color="secondary.main">
                                            <Link href={link.href} target="_blank"
                                                  rel="noopener" color="secondary">
                                                {link.text}
                                            </Link>
                                        </Typography>
                                    ))}
                                </Collapse>
                            </Box>


                        </CardContent>
                    </Card>

                    <Box
                        sx={{
                            mt: 4,
                            display: 'flex',
                            justifyContent: 'center',  // Centers the entire box horizontally
                            alignItems: 'center',      // Centers the entire box vertically (optional if you want)
                            backgroundColor: 'primary.main',
                            p: 3,
                            borderRadius: '8px',
                        }}
                    >
                        <Box
                            sx={{
                                maxWidth: 750,
                                width: '100%', // Ensure the inner box can grow to the full width
                            }}
                        >
                            <Typography variant="h5" color="text.primary" align="center">
                                Suggested Fighters
                            </Typography>

                            {/* Divider with styling */}
                            <Divider
                                sx={{
                                    mt: 2,
                                    mb: 4,
                                    backgroundColor: 'primary.dark',
                                    height: 2,
                                    width: '75%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    borderRadius: '1px',
                                }}
                            />

                            <Box
                                display="flex"
                                sx={{
                                    mt: 2,
                                    overflowX: 'auto',
                                    whiteSpace: 'nowrap',
                                    p: 2,
                                }}
                            >
                                {playerSwitcher.map((fighter) => (
                                    <Box
                                        key={fighter.displayName}
                                        onClick={() => switchFighter(fighter.displayName)}
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                        sx={{
                                            width: 150,
                                            height: 150,
                                            mx: 2,
                                            p: 2,
                                            backgroundColor: 'primary.main',
                                            borderRadius: '16px',
                                            textAlign: 'center',
                                            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.5)',
                                            transition: 'transform 0.3s ease',
                                            '&:hover': {
                                                cursor: 'pointer',
                                                transform: 'scale(1.05)',
                                            },
                                        }}
                                    >
                                        <Avatar
                                            src={fighter.headshot.href}
                                            alt={fighter.headshot.alt}
                                            sx={{
                                                width: 80,
                                                height: 80,
                                                mb: 1,
                                                border: 2,
                                                borderColor: 'primary.light',
                                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.30)', // Shadow for the avatar
                                            }}
                                        />
                                        <Typography sx={{ color: 'white', fontWeight: 'bold' }}>
                                            {fighter.displayName}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Box>


                </Box>
            ) : notFound ? (
                // If fighter is not found, display the not found message
                <Card sx={{
                    maxWidth: 600,
                    margin: 'auto',
                    mt: 4,
                    bgcolor: 'primary.main'
                }}>
                    <CardContent>
                        <Typography variant="h5" component="div"
                                    color="error.main" gutterBottom>
                            Fighter Not Found
                        </Typography>
                        <Typography variant="body2" color="text.secondary"
                                    gutterBottom>
                            We couldn't find any information about the fighter.
                            Please try searching for another name.
                        </Typography>
                    </CardContent>
                </Card>
            ) : (
                <Card sx={{
                    maxWidth: 600,
                    margin: 'auto',
                    mt: 4,
                    bgcolor: 'primary.main'
                }}>
                    <CardContent>
                        <Typography variant="h5" component="div"
                                    color="text.primary" gutterBottom>
                            Fetching Fighter Data
                        </Typography>
                        <Typography variant="body2" color="text.secondary"
                                    gutterBottom>
                            Please wait while we load the fighter's information.
                        </Typography>
                        <Box mt={2} sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <CircularProgress sx={{color: 'primary.light'}}/>
                        </Box>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
}

export default FighterPage;
