import {Box, Link, Typography} from "@mui/material";


const Footer = () => {
    return (
        <Box sx={{ bgcolor: 'primary.main', color: 'text.primary', py: 3, mt: 5, textAlign: 'center' }}>
            <Typography variant="body2">
                Designed, Developed, and Deployed by Nathan Ceci.
            </Typography>

            <Link href="https://www.nathanceci.com" target="_blank" color='secondary.main'>
                About Me
            </Link>

        </Box>
    );
};

export default Footer;